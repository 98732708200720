<template>
	<div class="staff-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Staff" filter="firstName" sort="firstName"
		               v-bind:order="1"
		               v-bind:fields="fields"
		               v-bind:data-array="staffs"
		               v-on:add-new="addStaff"
		               v-on:row-clicked="navigate($event)"></table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";

export default {
	name: "StaffTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Staff No.", key: "staffNo", classBinding: ["f-2"] },
				{ display: "Last Name", key: "lastName", classBinding: [] },
				{ display: "First Name", key: "firstName", classBinding: ["f-2"] },
				{ display: "Preferred Name", key: "preferredName", classBinding: [] },
				{ display: "Gender", key: "gender", classBinding: [] },
				{ display: "Phone", key: "phone", classBinding: [] },
				{ display: "Department", key: "department", classBinding: ["f-2"] },
				{ display: "Position", key: "position", classBinding: ["f-3"] },
			],
		}
	},
	
	computed: {
		staffs() {
			return this.$store.state.contents.staffs.map(staff => {
				return {...staff};
			});
		},
	},
	
	methods: {
		async addStaff() {
			await this.$router.push("/project/staff/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/project/staff/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>